import React from 'react';
import { Helmet } from 'react-helmet';
import { NotFoundTemplate } from '@we-agile-you/retrospective-app';
import { Layout } from '@we-agile-you/retrospective-app';

export default function NotFound() {
  return (
    <Layout>
      <Helmet title="Retrospectives | We Agile You" />
      <NotFoundTemplate />
    </Layout>
  );
}
